<template>
  <v-container>
    <v-row v-show="patientModel.item.extra_info.is_matched === 1">
      <v-col class="text-h6 pt-8">
        매칭 성사된 간병인
      </v-col>
    </v-row>
    <v-row v-show="patientModel.item.extra_info.is_matched === 1">
      <v-col>
        <v-card>
          <v-list class="pa-4">
            <span class="text-h6 font-weight-bold pr-2">{{ doneItem.basic_info.name }}</span
            >님
            <span class="text-caption pl-2"
              >{{ doneItem.basic_info.age }}세, {{ doneItem.basic_info.getGender() }}</span
            >
            <router-link :to="`/carers/${doneItem.extra_info.idx}`" class="pl-4 text-caption">
              자세히 보기
            </router-link>
          </v-list>
          <v-divider />
          <v-list class="pa-4">
            <div class="d-flex align-center">
              <div class="text-caption" style="width: 70px;">
                매칭일시
              </div>
              <div>
                {{ doneItem.match_info.updated_at | dayjsLocalDatetime }}
              </div>
            </div>
          </v-list>
          <v-divider />
          <v-list class="pa-4">
            <div class="d-flex align-center">
              <div class="text-caption" style="width: 70px;">
                전화번호
              </div>
              <div>
                {{ doneItem.basic_info.phone }}
              </div>
            </div>
          </v-list>
          <v-divider />
          <v-list class="pa-4">
            <div class="d-flex align-center">
              <div class="text-caption" style="width: 70px;">
                경력
              </div>
              <div>{{ doneItem.extra_info.career }}년</div>
            </div>
          </v-list>
          <v-divider />
          <v-list class="pa-4">
            <div class="d-flex align-center">
              <div class="text-caption" style="width: 70px;">
                자격증
              </div>
              <div>
                {{ doneItem.extra_info.license || 'N/A' }}
              </div>
            </div>
          </v-list>
          <v-divider />
          <v-list class="pa-4">
            <div class="d-flex align-center">
              <div class="text-caption" style="width: 70px;">
                자기소개
              </div>
              <div>
                {{ doneItem.extra_info.introduce || 'N/A' }}
              </div>
            </div>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-12 text-h6">
        신청 간병인 목록
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-simple-table class="tbl elevation-2">
          <template v-slot:default>
            <thead>
              <tr>
                <th v-for="item in applyItemsHeaders" :key="item.text" :class="`text-${item.align}`">
                  {{ item.text }}
                </th>
              </tr>
            </thead>
            <TbodyPreRender
              :is-loading="isLoading"
              :cols="applyItemsHeaders.length"
              :items-length="applyItems.length"
            />
            <tbody v-if="!isLoading">
              <tr v-for="item in applyItems" :key="`apply-patient-item-${item.extra_info.idx}`">
                <td class="text-center">
                  {{ item.match_info.created_at | dayjsLocalDatetime }}
                </td>
                <td class="py-2">
                  <v-chip
                    small
                    outlined
                    class="mr-2 ml-2 justify-center"
                    :color="item.basic_info.gender === 'male' ? 'blue' : 'red'"
                  >
                    {{ item.basic_info.age }}세, {{ item.basic_info.getGender() }}
                  </v-chip>
                  <router-link :to="`/carers/${item.extra_info.idx}`">
                    {{ item.basic_info.name }}
                  </router-link>
                </td>
                <!-- <td class="text-center pr-2">
                  {{ $options.filters.numberFormat((item.work_info.number_of_completions || 0)) }}
                </td> -->
                <td class="text-center">{{ item.extra_info.career }}년</td>
                <td class="text-center">
                  {{ item.extra_info.license || 'N/A' }}
                </td>
                <td class="py-2 text-center">
                  <v-chip outlined small>
                    <Promised :promise="item.extra_info.getAddress()">
                      <template v-slot:combined="{ data }">
                        {{ data }}
                      </template>
                    </Promised>
                  </v-chip>
                </td>
                <td>
                  <v-chip
                    v-if="value.item.extra_info.is_matched === 0"
                    outlined
                    small
                    @click="acceptCaregiverByPatient(item.extra_info.idx)"
                  >
                    수락하기
                  </v-chip>
                  <div v-else></div>
                  <v-chip v-if="value.item.extra_info.is_matched === 0" outlined small @click="sendMessage(item)">
                    환자번호 보내기
                  </v-chip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PatientMatch from './model/patientMatch'
import TbodyPreRender from '../_elements/table/TbodyPreRender'
import { CarerSchema } from '../carer/model/carerSchema'
import axios from 'axios'
import Config from '@/config'

const BS = 16

function pad(s) {
  const result = s + new Array(BS - (s.length % BS)).fill(String.fromCharCode(BS - (s.length % BS))).join('')
  return utils.utf8.toBytes(result)
}

export default {
  components: {
    TbodyPreRender
  },
  props: ['value'],
  data() {
    return {
      patientModel: null,
      model: new PatientMatch(),
      isLoading: false,
      applyItems: [],
      doneItem: new CarerSchema(),
      applyItemsHeaders: [
        { text: '지원일시', align: 'center' },
        { text: '프로필', align: 'center' },
        // { text: '매칭횟수', align: 'center' },
        { text: '경력', align: 'center' },
        { text: '자격증', align: 'center' },
        { text: '활동지역', align: 'center' },
        { text: '', align: 'center' }
      ]
    }
  },
  async created() {
    this.patientModel = this.value
    this.model.idx = this.patientModel.idx
    await this.getMatchedInfo()
  },
  methods: {
    async getMatchedInfo() {
      this.isLoading = true
      const { apply, done } = await this.model.getMatchedInfo()
      this.isLoading = false
      this.applyItems = apply
      this.doneItem = done
    },
    async acceptCaregiverByPatient(caregiverId) {
      try {
        await axios.post('https://nedjuzgla7.execute-api.ap-northeast-2.amazonaws.com/dev/caregiver/accept', {
          patientId: this.patientModel.idx,
          caregiverId
        })
        await this.$bus.$emit('successMessage', '전송 요청에 성공하였습니다.')
        this.$router.go(this.$router.currentRoute)
      } catch (e) {
        await this.$bus.$emit('errorMessage', '전송 요청에 실패하였습니다.')
      }
    },
    async sendMessage(caregiver) {
      const caregiverPhone = caregiver.basic_info.phone
      const patientPhone = this.patientModel.item.basic_info.phone

      await axios.post(`${Config.Api.Service2.url}/send-message`, {
        message: `지원한 환자의 연락처를 보내드립니다.\n\n연락처 : ${patientPhone.slice(
          0,
          3
        )}-${patientPhone.slice(3, 7)}-${patientPhone.slice(
          7,
          11
        )}\n\n전화 연결 시 헬퍼에서 지원했다고 말씀하시고 일하기로 결정되면 꼭 보호자분께 본인 성함 말씀드리고 카카오톡에서 '수락하기'를 눌러달라고 해주세요. \n간병 성사 여부를 저희한테 문자나 전화로 꼭 알려주세요.`,
        phone: caregiverPhone
      })
      await this.$bus.$emit('successMessage', '전송 요청에 성공하였습니다.')
    }
  }
}
</script>

<style lang="scss" scoped></style>
