/* eslint-disable max-classes-per-file */
import { CarerSchema } from '../../carer/model/carerSchema'
import Api from './api'

const PatientMatch = class {
  #idx = null

  constructor() {
    this.query = ''
    // this.pagination = new Pagination()
    this.pagination = {}
  }

  set idx(idx) {
    this.#idx = idx
  }

  async getMatchedInfo() {
    const { apply, done } = await Api.getMatchedInfo(this.#idx)
    return {
      apply: apply.map((item) => new CarerSchema(item)),
      done: new CarerSchema(done || {})
    }
  }
}

export default PatientMatch
